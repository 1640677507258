import { mapMutations } from 'vuex'

export default {
  methods: {
    ...mapMutations('breadcrumbs', {
      setBreadcrumbs: 'SET',
      pushBreadcrumb: 'PUSH',
      popBreadcrumb: 'POP',
      replaceBreadcrumb: 'REPLACE',
      emptyBreadcrumbs: 'EMPTY'
    })
  }
}
